// @flow
import './index.less';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Markdown from 'react-markdown';

const TermsSection = (): React$Node => {
  const { t } = useTranslation();

  return (
    <div className="terms-container">
      <Markdown className="content" allowDangerousHtml>
        {t('Terms_Content')}
      </Markdown>
    </div>
  );
};

export default TermsSection;
