// @flow
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';

import HeaderSection from '_components/HeaderSection';
import Layout from '_components/Layout';
import TermsSection from '_components/TermsSection';
import useAnalytics, { event } from '_hooks/useAnalytics';

const Terms = (): React$Node => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  useEffect(() => {
    track({
      group: event.group.Terms,
      action: event.action.Viewed,
    });
  }, [track]);

  return (
    <Layout floatingNavbar={false} inverseFooter>
      <HeaderSection title={t('Common_TermsOfUse')} backgroundColor="#160f10" />
      <TermsSection />
    </Layout>
  );
};

export default Terms;
