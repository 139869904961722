// @flow
import './index.less';

import React from 'react';
import Markdown from 'react-markdown';

type Props = {
  title: React$Node,
  description?: string,
  backgroundImage?: number | string,
  backgroundColor?: string,
};

const HeaderSection = ({ title, description, backgroundImage, backgroundColor }: Props): React$Node => (
  <section className="header" style={{ backgroundColor }}>
    {backgroundImage && <img src={backgroundImage} alt={title} className="background-image" />}
    <div className="container">
      <div className="title">{title}</div>
      {description && <Markdown className="description">{description}</Markdown>}
    </div>
  </section>
);

export default HeaderSection;
